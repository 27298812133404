body {
  margin: 0%;
  padding: 0%;
  scroll-behavior: smooth;
  font-family: 'Inter';
}
.khattu a {
  text-decoration: none !important;
  color: #888888;
  list-style: none !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
