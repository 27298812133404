.inp{
    box-shadow: 5px -1px 47px 0px rgba(0,0,0,0.75);
    -webkit-box-shadow: 5px -1px 47px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 5px -1px 47px 0px rgba(0,0,0,0.75);

    
}
.contributor
  {
      background-color: #FFFFFF;
      box-shadow: 0px 4px 12px rgba(201, 216, 230, 0.15);
      border-radius: 10px;
      border: 1px solid #fff;
      color: #056ac9;
      font-weight: 600;
      font-size: 18px;
      padding: 15px 18px;
  }
  .center-data{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .load-button{
    height: 30px;
    font-size: 18px;
    border: none;
    outline: none;
    background-color: #2188E7;
    font-weight: 500;
    color: #F5F5F5;
  }
  .Blog {
    background-color: #FFF;
    color: #1991FF;
    border: 1px solid #000000;
    text-align: center;
    padding: 10px 60px;
    width: auto;
    border-radius: 10px;
}
.custom-carousel .carousel-control-prev,
.custom-carousel .carousel-control-next,
.custom-carousel .carousel-indicators {
  display: none;
}
  /* .load-button:hover{
    height: 35px;
    font-size: 20px;
    outline: none;
    background-color: #59AFFF;
    font-weight: 600;
    color:  #8A8C94;
    box-shadow: 0px 1px 1px 0px #59AFFF;
  } */
