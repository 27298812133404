.dwn {
    background-color: #d2e5f7;
    color: #1991FF;
    padding: 10px 15px;
    float: left;
    border-radius: 5px;
    border: none;
}

.dwn2 {
    background-color: #EFFFF6;
    color: #129C73;
    padding: 10px 15px;
    float: left;
    border-radius: 7px;
    border: none;
}

.desktopview {
    display: block !important;
    overflow-y: hidden;
}

.mobileview {
    display: none !important;
}

.Versions h3 {
    margin-top: 6rem;
    color: #5b5b5b;
}

.Versions ul li {
    background-color: #ffffff;
    color: #8a8a8a;
    list-style: none;
    margin: 10px 0px;
    padding: 10px 15px;
    border-radius: 10px;
}

.form-select {
    display: block;
    width: 100%;
    padding: .375rem 2.25rem .375rem .75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-image: url();
    background-repeat: no-repeat;
    background-position: right .75rem center;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: .375rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.Update {
    background-color: #EFF4FF;
    color: #1991FF;
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
}
@media (max-width: 768px) {
    .desktopview {
        display: none !important;
    }

    .mobileview {
        display: block !important;
        margin-top: 3.8rem;
        box-shadow: none !important;
        backdrop-filter: blur(10px);
    }

    .short {
        font-size: 20px;
        padding: 10px 0px;
    }

}

/* Default: non-sticky */
.sticky-div {
    position: static;
  }
  
  /* Large screens and above: sticky */
  @media (min-width: 992px) {
    .sticky-div {
      position: fixed;
    }
  }
  