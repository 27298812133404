.ss1 {
    display: flex;
    justify-content: center;
    width: auto;
}
.DownloadAPK {
    background-color: #FFE609;
    padding: 10px 15px;
    float: left;
    border-radius: 5px;
    border: none;
    color: black;
}
.Bucks {
    background-color: #1991FF;
    color: #FFF;
    padding: 10px 15px;
    float: left;
    font-size: 20px;
    border-radius: 5px;
    border: none;
    text-align: center;
    width: auto;
    border: none;
}
.Quiz h1 {
    font-weight: 700;
    padding: 50px 10px;
}
.latestBlog {
    display: flex;
}
.item {
    padding-left: 15px;
    /* width: 246px; */
}
.View {
    background-color: #FFF;
    color: #1991FF;
    width: auto;
    padding: 10px 40px;
    border-radius: 40px;
    border: none;
}
.scrolling-contain ul li {
    height: 3rem;
    list-style: none;
    color: #FFE609;
}
.Rooms {
    background-color: #FFE609;
    padding: 8px 25px;
    float: left;
    border-radius: 5px;
    border: none;
    width: auto;
    color: black;
    border: none;
}
.Blogs {
    background-color: #FFF;
    color: #1991FF;
    border: 1px solid #000000;
    text-align: center;
    padding: 10px 60px;
    width: auto;
    border-radius: 10px;
}
.custom-carousel .carousel-control-prev,
.custom-carousel .carousel-control-next,
.custom-carousel .carousel-indicators {
  display: none;
}