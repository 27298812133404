.head{
    font-size: 35px;
    font-weight: 700;
  }
  .para{
      font-weight: 500;
      color: rgb(89, 89, 89);
      /* font-family: "Work Sans"; */
      font-size: 17px;
  }
  .name{
      font-weight: 700;
      color: rgb(84, 81, 81);
      
  }
  .contributor
  {
      background-color: #FFFFFF;
      box-shadow: 0px 4px 12px rgba(201, 216, 230, 0.15);
      border-radius: 10px;
      border: 1px solid #fff;
      color: #1991FF;
      padding: 15px 18px;
  }
  
.arrow{
    color: #1991FF;
}  
.story{
    background-color: #FFFFFF;
      box-shadow: 0px 4px 12px rgba(201, 216, 230, 0.15);
      border-radius: 10px;
      border: 1px solid rgb(63, 62, 62);
      padding: 8px 10px;
}